.generalCard-container {
  position: relative;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 223px;
  margin-bottom: 15px;
}

.generalCard-container-metric {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  width: 80%;
  margin-left: 20px;
  margin-right: 20px;
  color: var(--gray600);
}
.generalCard-container-metric-text {
  color: var(--gray600);
}
.generalCard-container-description {
  padding-bottom: 20px;
  margin-top: 10px;
  width: 50%;
  margin-left: 20px;
  color: var(--gray700);
}
