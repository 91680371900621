.chatBubble-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 70%;
    margin-bottom: 16px;
    margin-top: 16px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
}
    .chatBubble-container-file {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 5px;
        border-radius: 5px;
    }
        .chatBubble-container-file-img {
            width: auto;
            height: 100px;
        }
        .chatBubble-container-file-caption {
            margin-top: 5px;
        }
    .chatBubble-container-mensaje {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        border-radius: 10px;
    }
        .chatBubble-container-mensaje-word {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            color: var(--white);
        }
        .chatBubble-container-mensaje-toolTip {
            visibility: hidden;
            opacity: 0;
            background-color: var(--gray700);
            color: var(--white);
            text-align: center;
            border-radius: 4px;
            padding: 5px;
            position: absolute;
            white-space: nowrap;
            transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
            z-index: 1;
        }
        .chatBubble-container-mensaje:hover .chatBubble-container-mensaje-toolTip {
            visibility: visible;
            opacity: 1;
        }
    .chatBubble-container-time {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
    }
        .chatBubble-container-time-fecha {
            color: var(--gray300);
        }
        .chatBubble-container-time-toolTip {
            visibility: hidden;
            opacity: 0;
            background-color: var(--gray700);
            color: var(--white);
            text-align: center;
            border-radius: 4px;
            padding: 5px;
            position: absolute;
            white-space: nowrap;
            transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
            z-index: 1;
        }
        
        .chatBubble-container-time:hover .chatBubble-container-time-toolTip {
            visibility: visible;
            opacity: 1;
        }