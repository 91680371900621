.dateBetweenOptionListComponent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
  .dateBetweenOptionListComponent-container-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
    .dateBetweenOptionListComponent-container-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
      .dateBetweenOptionListComponent-container-list-item-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 15px;
        width: 15px;
        border-radius: 25px;
        border: 1px solid var(--primary500);
        margin-right: 5px;
      }
        .dateBetweenOptionListComponent-container-list-item-circle-inner {
          height: 80%;
          width: 80%;
          border-radius: 25px;
          background-color: var(--primary500);
          transition: all 0.5s ease;
        }
  .dateBetweenOptionListComponent-container-selectedOptionList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
    .dateBetweenOptionListComponent-container-selectedOptionList > * {
      width: 49%;
    }