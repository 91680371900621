.polizasCobranza-container {
    width: 100%;
    margin-top: 57px;
}
    .polizasCobranza-container-data {
        width: 100%;
    }
        .polizasCobranza-container-data-list {
            display: flex;
            flex-direction: column;
        }
            .polizasCobranza-container-data-list-item {
                width: 100%;
            }
                .polizasCobranza-container-data-list-item-data {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    width: 100%;
                }
                    .polizasCobranza-container-data-list-item-data-date {
                        color: var(--gray700);
                        width: 15%;
                    }
                    .polizasCobranza-container-data-list-item-data-estado {
                        color: var(--gray700);
                        width: 15%;
                    }
                    .polizasCobranza-container-data-list-item-data-details {
                        display: flex;   
                        flex-direction: column;
                        width: 70%;
                    }
                        .polizasCobranza-container-data-list-item-data-details-text {
                            color: var(--gray700);
                            margin-bottom: 2px;
                        }
                .polizasCobranza-container-data-list-item-line {
                    width: 100%;
                    height: 1px;
                    background-color: var(--gray200);
                    margin: 20px 0;
                }