.genericTableAndFilters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
  .genericTableAndFilters-container-uploads {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 97%;
    margin-left: 20px;
    margin-top: 40px;
  }
    .genericTableAndFilters-container-uploads > * {
      width: 49.5%;
    }
  .genericTableAndFilters-container-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    margin-top: 100px;
  }
    .genericTableAndFilters-container-title-texts {
      display: flex;
      flex-direction: column;
    }
      .genericTableAndFilters-container-title-texts-title {
        color: var(--gray700);
        margin-left: 20px;
      }
      .genericTableAndFilters-container-title-texts-subTitle {
        color: var(--gray700);
        margin-left: 20px;
      }
    .genericTableAndFilters-container-title-buttonWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 10px;
      width: 30%;
    }
      .genericTableAndFilters-container-title-buttonWrapper-primaryCreate {
        width: 50%;
      }
      .genericTableAndFilters-container-title-buttonWrapper-secondaryCreate {
        color: var(--primary500);
        margin-left: auto;
        cursor: pointer;
        width: 50%;
      }
