.mainButton-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary500);
    color: var(--white);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    border-radius: 20px;
    height: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
    .mainButton-container:active {
        font-size: 0.95rem;
        background-color: var(--primary400);
    }
.mainButton-containerDis {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary500);
    color: var(--white);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    border-radius: 20px;
    height: 40px;
    margin: 0;
    padding: 0;
    opacity: 0.4;
}

.mainButton-containerSinFondo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    border-radius: 20px;
    height: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
    .mainButton-containerSinFondo:active {
        font-size: 0.95rem;
        color: var(--primary700);
    }

.mainButton-containerDisSinFondo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray300);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    border-radius: 20px;
    height: 40px;
    margin: 0;
    padding: 0;
    opacity: 0.4;
}

.mainButton-containerGoogle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    color: var(--gray700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    border: 1px solid var(--gray700);
    border-radius: 20px;
    height: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
    .mainButton-containerGoogle:active {
        font-size: 0.95rem;
        color: var(--gray700);
    }

.mainButton-containerDisGoogle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    color: var(--white);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    border-radius: 20px;
    height: 40px;
    margin: 0;
    padding: 0;
    opacity: 0.4;
}

.mainButton-containerInverted {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--primary500);
    border: 2px solid var(--primary500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    border-radius: 20px;
    height: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
    .mainButton-containerInverted:active {
        font-size: 0.95rem;
        border: 2px solid var(--primary400);
        color: var(--primary400);
    }

.mainButton-containerDisInverted {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray50);
    color: var(--primary500);
    border: 2px solid var(--primary500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    border-radius: 20px;
    height: 40px;
    margin: 0;
    padding: 0;
    opacity: 0.4;
}