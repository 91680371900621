.chatSender-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8%;
    background-color: var(--white);
    margin-top: auto;
}
    .chatSender-container-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 97.5%;
    }
        .chatSender-container-wrap-menu-button {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 150ms ease;
            border-radius: 15px;
            user-select: none;
            height: 22px;
            width: 22px;
        }
            .chatSender-container-wrap-menu-button:hover {
                background-color: var(--gray100);
            }
            .chatSender-container-wrap-menu-button-text {
                color: var(--primary500);
            }
            .chatSender-container-wrap-menu-list {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background-color: var(--gray100);
                position: absolute;
                top: 78%;
            }
                .chatSender-container-wrap-menu-list-wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 5px;
                }
                    .chatSender-container-wrap-menu-list-wrap-item {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        padding: 5px 0;
                        border-radius: 5px;
                        width: 100%;
                    }
                        .chatSender-container-wrap-menu-list-wrap-item:hover {
                            background-color: var(--gray200);
                        }
                        .chatSender-container-wrap-menu-list-wrap-item-wrap {
                            display: flex;
                            align-items: center;
                            padding: 0 5px;
                        }
                            .chatSender-container-wrap-menu-list-wrap-item-wrap-text {
                                width: fit-content;
                                color: var(--gray700);
                            }
        .chatSender-container-wrap-input {
            outline: none;
            border: none;
            color: var(--gray700);
            width: 92%;
            margin-left: 10px;
            background-color: transparent;
        }
            .chatSender-container-wrap-input::placeholder {
                color: var(--gray300);
            }