.chatBox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 66px;
    cursor: pointer;
}
    .chatBox-container-main {
        width: 90%;
    }
        .chatBox-container-main-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 5px;
        }
            .chatBox-container-main-top-numero {
                color: var(--gray600);
            }
            .chatBox-container-main-top-fecha {
                color: var(--gray400);
            }
        .chatBox-container-main-bot {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }
            .chatBox-container-main-bot-ultimoMensaje {
                color: var(--gray400);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 50%;
            }
            .chatBox-container-main-bot-mensajesNoLeidos {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--primary500);
                border-radius: 25px;
                height: 15px;
                min-width: 15px;
                margin-left: auto;
            }
                .chatBox-container-main-bot-mensajesNoLeidos-number {
                    color: var(--white);
                }