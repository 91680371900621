.cSVUploader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
    .cSVUploader-container-title {
        margin: 16px 0;
    }
    .cSVUploader-container-dropBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px dashed #ccc;
        padding: 20px;
        height: 200px;
        width: 400px;
    }
    .cSVUploader-container-buttonWrapper {
        width: 40%;
        margin-top: 16px;
    }