.polizasItem-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    justify-content: space-between;
}
    .polizasItem-container-data {
        display: flex;
        flex-direction: column;
        width: 75%;
    }
        .polizasItem-container-data-title {
            color: var(--gray700);
            margin: 0;
            margin-bottom: 10px;
            margin-top: -20px;
        }
        .polizasItem-container-data-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 40px;
        }
        .polizasItem-container-data-section > * {
            width: 31%;
            margin-bottom: 10px;
            margin-right: 20px;
        }
        .polizasItem-container-data-label {
            color: var(--gray2);
        }
        .polizasItem-container-data-double {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            width: 64%;
            margin-bottom: 40px;
            margin-top: -40px;
        }