.archivos-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
    overflow-x: auto;
}
    .archivos-container-main {
        margin-left: 103px;
        width: 100%;
    }
