.chatSection-container {
    display: flex;
    flex-direction: column;
    min-width: 63%;
    max-width: 63%;
    height: 90%;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    margin-left: 10px;
}
    .chatSection-container-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 8%;
        background-color: var(--white);
    }
        .chatSection-container-top-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 95%;
        }
            .chatSection-container-top-wrap-highlight {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 12.5%;
            }
                .chatSection-container-top-wrap-highlight-count {
                    color: 'var(--gray700)'
                }
                .chatSection-container-top-wrap-highlight-arrows {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: auto;
                }
            .chatSection-container-top-wrap-numero {
                color: var(--gray700);
            }
            .chatSection-container-top-wrap-input {
                outline: none;
                border: none;
                color: var(--gray700);
                width: 80%;
                margin-left: 10px;
                background-color: transparent;
            }
                .chatSection-container-top-wrap-input::placeholder {
                    color: var(--gray300);
                }
            .chatSection-container-top-wrap-icons {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
                .chatSection-container-top-wrap-icons-more {
                    position: relative;
                }
                    .chatSection-container-top-wrap-icons-more-list {
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        background-color: var(--white);
                        z-index: 2;
                        border-radius: 5px;
                        overflow: hidden;
                        width: max-content;
                        transform: translateX(-90%);
                    }
                        .chatSection-container-top-wrap-icons-more-list-text {
                            cursor: pointer;
                            color: var(--gray700);
                            padding: 10px;
                            width: max-content;
                            background-color: var(--gray50);
                        }
                        .chatSection-container-top-wrap-icons-more-list-text:hover {
                            background-color: var(--gray100);
                        }
                        .chatSection-container-top-wrap-icons-more-list-text:active {
                            background-color: var(--primary500);
                            color: var(--white);
                        }
    .chatSection-containerMain {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 94%;
        position: relative;
    }
        .chatSection-containerMain-chat-drag {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 30%;
            height: 63.2%;
            left: 40.5%;
            width: 54.5%;
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px dashed red;
            text-align: center;
            z-index: 2;
        }
            .chatSection-containerMain-chat-drag-text {

            }
        .chatSection-container-main-chat {
            width: 100%;
            height: 100%;
            background-color: var(--gray100);
            overflow-y: scroll;
            overflow-x: hidden;
        }
            .chatSection-container-main-chat::-webkit-scrollbar {
                width: 10px;
                height: 10px;
                background-color: var(--gray100);
            }
    .chatSection-container-sender-modal {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 40%;
        bottom: 0%;
        position: absolute;
        background-color: var(--white);
        overflow: auto;
    }
        .chatSection-container-sender-modal-wrap {
            display: flex;
            flex-direction: column;
            position: relative;
        }
        .chatSection-container-sender-modal-title {
            color: var(--gray700);
            margin-top: 20px;
            margin-bottom: 10px;
            margin-left: 5px;
        }
        .chatSection-container-sender-modal-list {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
        }
            .chatSection-container-sender-modal-list-item {
                width: 100%;
                cursor: pointer;
            }
                .chatSection-container-sender-modal-list-item:hover {
                    background-color: var(--gray50);
                }
                .chatSection-container-sender-modal-list-item:active {
                    background-color: var(--gray100);
                }
                .chatSection-container-sender-modal-list-item-wrapp {
                    width: 97.5%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: auto;
                    padding: 5px 0;
                }
                    .chatSection-container-sender-modal-list-item-wrapp-label {
                        color: var(--gray700);
                    }
                    .chatSection-container-sender-modal-list-item-wrapp-circle {
                        height: 10px;
                        width: 10px;
                        border: 1px solid var(--primary500);
                        border-radius: 10px;
                        margin-left: auto;
                        overflow: hidden;
                    }
                        .chatSection-container-sender-modal-list-item-wrapp-circle-inner {
                            height: 10px;
                            width: 10px;
                            border-radius: 10px;
                            background-color: var(--primary500);
                            transition: all 500ms ease;
                        }
        .chatSection-container-sender-modal-button {
            width: 90%;
            margin: 0 auto;
            position: sticky;
            bottom: 3%;
        }