.burgerMenu-container {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    width: 102px;
    height: 100vh;
    border-right-width: 1px;
    border-right-color: var(--gray200);
    border-right-style: solid;
    position: fixed;
    transition: all 0.25s ease;
    align-items: center;
    z-index: 5;
}
    .burgerMenu-container:hover {
        width: 243px;
    }
    .burgerMenu-container-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 78px;
        min-height: 78px;
        margin-top: 20px;
        border-radius: 50px;
        background-color: var(--logoBackGroud);
        cursor: pointer;
    }
        .burgerMenu-container-logo-img {
            margin: 0 auto;
            width: 80%;
            cursor: pointer;
        }
    .burgerMenu-container-line {
        display: flex;
        width: 70%;
        height: 1px;
        background-color: var(--gray200);
        margin-top: 14px;
    }
    .burgerMenu-container-itemList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 20px;
        overflow-y: auto;
    }