.chatUploadFile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--gray100);
    z-index: 9;
}
    .chatUploadFile-container-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 97%;
        margin: 0 auto;
        margin-top: 10px;
    }
        .chatUploadFile-container-header-icons {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    .chatUploadFile-container-file {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 100%;
    }
        .chatUploadFile-container-file-image {
            width: 10%;
            margin: 0 auto;
            margin-bottom: 10px;
        }
        .chatUploadFile-container-file-text {
            color: var(--gray700);
            text-align: center;
            margin-top: 5px;
        }
    .chatUploadFile-container-image {
        width: 30%;
    }
    .chatUploadFile-container-caption {
        width: 30%;
    }
        .chatUploadFile-container-caption-input {
            outline: none;
            border: 1px solid var(--gray200);
            border-radius: 10px;
            padding: 5px 10px;
            color: var(--gray700);
            width: 100%;
            margin-left: 10px;
            background-color: transparent;
        }
            .chatUploadFile-container-caption-input::placeholder {
                color: var(--gray300);
            }
    .chatUploadFile-container-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 97%;
        margin: 0 auto;
        margin-bottom: 10px;
    }
        .chatUploadFile-container-footer-text {
            color: var(--gray700);
        }