.uploadingComponent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    border-radius: 15px;
    border: 2px dashed var(--gray200);
}
    .uploadingComponent-container-main {
        width: 96%;
        height: 95%;
    }
        .uploadingComponent-container-main-text {
            color: var(--gray300);
        }
        .uploadingComponent-container-main-line {
            width: 100%;
            height: 1px;
            margin-top: 8px;
            background-color: var(--gray200);
        }
        .uploadingComponent-container-main-list {
            display: flex;
            flex-direction: column;
        }
            .uploadingComponent-container-main-list-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 8px;
            }
            .uploadingComponent-container-main-list-item-text {
                color: var(--gray700);
            }
            .uploadingComponent-container-main-list-item-bar {
                width: 60%;
                height: 6px;
                border-radius: 5px;
                overflow: hidden;
                background-color: var(--gray200);
                position: relative;
            }
                .uploadingComponent-container-main-list-item-bar-inProgressBar {
                    width: 40%;
                    height: 6px;
                    border-radius: 5px;
                    animation: moveRight 4s linear infinite; /* Adjust the duration as needed */
                    position: absolute;
                    background-color: #7963E0;
                }
                .uploadingComponent-container-main-list-item-bar-errorBar {
                    width: 100%;
                    height: 100%;
                    background-color: var(--errorPrimary);
                }
                .uploadingComponent-container-main-list-item-bar-completedBar {
                    width: 100%;
                    height: 100%;
                    background-color: var(--successPrimary);
                }

                @keyframes moveRight {
                    0% {
                      transform: translateX(-150%);
                    }
                    100% {
                      transform: translateX(250%);
                    }
                  }