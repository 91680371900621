.uploadComponent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    border-radius: 15px;
    border: 2px dashed var(--gray200);
}
    .uploadComponent-container-text {
        color: var(--gray300);
        margin: 16px 0;
    }
    .uploadComponent-container-label {
        cursor: pointer;
    }
        .uploadComponent-container-label-look {
            border-radius: 100px;
            background: var(--primary500);
            padding: 10px 20px;
        }
            .uploadComponent-container-label-look-text {
                color: var(--white);
            }