.label-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.label-container-toolTip {
    visibility: hidden;
    opacity: 0;
    background-color: var(--gray700);
    color: var(--white);
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    white-space: nowrap;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    z-index: 1;
}

.label-container:hover .label-container-toolTip {
    visibility: visible;
    opacity: 1;
}
