.mensajes-container {
    display: flex;
    background-color: var(--gray50);
    height: 100vh;
    width: 100%;
}
    .mensajes-container-main {
        margin-left: 103px;
        width: 100%;
        height: 100%;
    }
        .mensajes-container-main-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 100px;
            height: calc(100% - 100px);
        }
            .mensajes-container-main-content-title {
                margin-left: 20px;
                color: var(--gray700);
            }
            .mensajes-container-main-content-section {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-top: 47px;
                height: calc(100% - 47px);
            }
                .mensajes-container-main-content-section-tabList {
                    border-radius: 0px 15px 0px 0px;
                    background: var(--gray500);
                    padding: 20px;
                    height: 100%;
                }
                    .mensajes-container-main-content-section-tabList > * {
                        margin-bottom: 16px;
                    }