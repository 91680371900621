.tableSubSections-container {
  display: flex;
  width: 97.5%;
  border-bottom: 1px solid var(--gray300);
  margin-left: 20px;
  margin-bottom: -15px;
  margin-top: 40px;
}
  .tableSubSections-container-item {
    padding: 0 20px;
    padding-bottom: 5px;
    margin-bottom: -1px;
  }
    .tableSubSections-container-item-text {

    }