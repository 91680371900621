.estadoCoberturaListModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
    .estadoCoberturaListModal-container-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
        .estadoCoberturaListModal-container-top-text {
            color: var(--gray700);
            font-family: Lato, sans-serif;
        }
        .estadoCoberturaListModal-container-top-close {
            color: var(--gray700);
            cursor: pointer;
            font-family: Lato, sans-serif;
        }
    .estadoCoberturaListModal-container-list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        .estadoCoberturaListModal-container-list-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-top: 1px solid var(--gray100);
            padding-top: 8px;
            margin-bottom: 8px;
        }
            .estadoCoberturaListModal-container-list-item-title {
                padding: 5px;
                border-radius: 5px;
                width: fit-content;
                font-family: Lato, sans-serif;
            }
            .estadoCoberturaListModal-container-list-item-text {
                width: 75%;
                color: var(--gray400);
                font-family: Lato, sans-serif;
            }