.tableHeader-container {
    display: flex;
    background-color: var(--gray50);
    position: fixed;
    width: calc(100% - 100px);
    height: 80px;
    z-index: 3;
}
    .tableHeader-container-wrap {
        margin-left: auto;
        margin-top: 10px;
        margin-right: 10px;
    }