.globalWhatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.globalWhatsapp:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
    .globalWhatsapp-img {
        width: 100%;
        transition: all 0.5s ease;
        cursor: pointer;
    }
        .globalWhatsapp-img:active {
            transform: scale(0.8)
        }

.globalWhatsapp-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 25px;
    background-color: var(--primary500);
}
    .globalWhatsapp-loader-loader {
        width: 65%;
        height: 65%;
        border: 2.5px solid var(--white);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 