.chats-container {
    display: flex;
    flex-direction: row;
    width: 92.5%;
}
    .chats-container-hub {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 30%;
        margin-top: 15px;
    }
        .chats-container-hub-title {
            display: flex;
            flex-direction: row;
            width: 95%;
            align-items: center;
        }
            .chats-container-hub-title-text {
                color: var(--gray600);
                width: 100%;
            }
        .chats-container-hub-searcher {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 95%;
            border-radius: 20px;
            border: 1px solid var(--gray300);
            background: var(--white);
            height: 40px;
            margin-top: 20px;
        }
            .chats-container-hub-searcher-input {
                outline: none;
                border: none;
                color: var(--gray700);
                width: 85%;
                margin-left: 10px;
                background-color: transparent;
            }
                .chats-container-hub-searcher-input::placeholder {
                    color: var(--gray300);
                }
        .chats-container-hub-chats {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 77%;
            overflow-y: scroll;
            margin-top: 15px;
        }
    .chats-container-chat {
        display: flex;
        width: 68%;
    }