.userData-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    justify-content: space-between;
}
    .userData-container-data {
        display: flex;
        flex-direction: column;
        width: 75%;
    }
        .userData-container-data-title {
            color: var(--gray700);
            margin: 0;
            margin-bottom: 10px;
            margin-top: 20px;
        }
        .userData-container-data-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%
        }
            .userData-container-data-section-selector {
                width: 31%;
                height: 64.4px;
                margin-right: 20px;
                position: relative;
            }
                .userData-container-data-section-selector-label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 5px;
                }
                    .userData-container-data-section-selector-label-text {
                        color: var(--gray300);
                    }
                    .userData-container-data-section-selector-label-required {
                        color: var(--errorPrimary);
                    }
            .userData-container-data-section > * {
                width: 31%;
                margin-bottom: 20px;
                margin-right: 20px;
            }
                .userData-container-data-section-customInput {
                    width: 31%;
                    margin-right: 20px;
                }
                    .userData-container-data-section-customInput-label {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 5px;
                    }
                        .userData-container-data-section-customInput-label-text {
                            color: var(--gray300);
                        }
                        .userData-container-data-section-customInput-label-required {
                            color: var(--errorPrimary);
                        }
                    .userData-container-data-section-customInput-inputs {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }
                        .userData-container-data-section-customInput-inputs-input {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border: 1px solid var(--gray200);
                            box-sizing: border-box;
                            border-radius: 15px;
                            background-color: var(--white);
                            padding: 10px;
                            outline: none;
                            color: var(--gray700);
                            height: 45px;
                        }
                        .userData-container-data-section-customInput-inputs-input:focus {
                            border: 1px solid var(--primary500);
                        }
                        .userData-container-data-section-customInput-inputs-input::placeholder {
                            color: var(--gray300);
                        }
                        .userData-container-data-section-customInput-inputs-input:focus::placeholder {
                            color: var(--gray300);
                        }
            .userData-container-data-section-triple {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                width: 31%;
                margin-bottom: 10px;
            }
                .userData-container-data-section-triple > * {
                    width: 30.5%;
                }
            .userData-container-data-section-status {
                display: flex;
                flex-direction: column;
                width: fit-content;
            }
                .userData-container-data-section-status-label {
                    color: var(--gray300);
                    margin: 0;
                    padding: 0;
                    margin-bottom: 5px;
                }
                .userData-container-data-section-status-data {
                    color: var(--gray700);
                    padding: 2.5px 5px;
                    border-radius: 5px;
                }
    .userData-container-editable {
        display: flex;
        flex-direction: column;
        width: 25%;
        position: fixed;
        right: 30px;
        bottom: 20px;
    }
        .userData-container-editable-edit {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--primary500);
            width: 40px;
            height: 40px;
            border-radius: 25px;
            background-color: var(--gray50);
            margin-left: auto;
            cursor: pointer;
        }
            .userData-container-editable-edit-img {
                width: 50%;
                height: 50%;
            }
        .userData-container-editable-editing {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
        }
            .userData-container-editable-editing > * {
                width: 40%;
            }